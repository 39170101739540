import React, { useEffect, useState } from 'react';
import EnquireNowForm from '../common/EnquireNowForm';
import { S3Url } from '../../../Config';

const Form = ({campaignId}) => {
    const [dynamicInputs, setDynamicInputs] = useState();
    const [customInitialValues, setCustomInitialValues] = useState("");

    useEffect(() =>{
        fetch(S3Url+'documents/json/concepts.json')
        .then(response => { if(!response.ok){throw new Error("Network response was not ok")} return response.json(); })
        .then(data => {
            var dynamicInititalValues = { name: '', email: '', phone: '', state: '', district: '', city: '', pincode: '',  campaign_id: campaignId };
            for(const [key] of Object.entries(data.enquiryrequirements.c_stage.atum)){ dynamicInititalValues[key.toLowerCase().split(' ').join('')] = ""; }
            setCustomInitialValues(dynamicInititalValues);
            setDynamicInputs(data.enquiryrequirements.c_stage.atum);
        })
        .catch(error =>{ console.log(error); });
    }, [campaignId]);

    return (
        <div>
            <section className="bg-white22 py-1">
                <div className="container">
                    <div className="row">
                        { (customInitialValues !== "") && <EnquireNowForm dynamicInputs={dynamicInputs} customInitialValues={customInitialValues} campaignId={campaignId} /> }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Form