import { ImageUrl } from "../../../Config";

export const InsideBannerList = [
    {
        id: 1,
        title: 'about',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'About Us'
    },
    {
        id: 2,
        title: 'portfolio',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Portfolio'
    },
    {
        id: 3,
        title: 'technical-specs',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Technical Specs'
    },
    {
        id: 4,
        title: 'news',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'News & Events'
    },
    {
        id: 5,
        title: 'blog',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blogs'
    },
    {
        id: 6,
        title: 'contact',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Contact Us'
    },
    {
        id: 7,
        title: 'terms-conditions',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Terms & Conditions'
    },
    {
        id: 8,
        title: 'privacy-policy',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Privacy & Policy'
    },
    {
        id: 9,
        title: 'cookie-policy',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Cookie Policy'
    },
    {
        id: 10,
        title: 'why-atum-is-the-ultimate-roofing-solution-to-resist-extreme-weathers',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 11,
        title: 'why-the-world-needs-to-go-solar-now-more-than-ever',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 12,
        title: 'five-reasons-why-you-should-buy-your-solar-rooftop-plant-during-a-recession',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 13,
        title: 'why-investing-in-a-solar-roof-is-the-only-trustworthy-scheme-to-make-money',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 14,
        title: 'top-ten-reasons-to-choose-a-solar-roof-and-not-a-typical-solar-panel',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 15,
        title: 'solar-glossary-101-what-you-need-to-know',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
    {
        id: 16,
        title: 'why-going-solar-is-the-best-alt-energy-solution-for-the-future',
        image: ImageUrl+'images/slider/insideslider.jpg',
        heading: 'Blog Details'
    },
];