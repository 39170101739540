import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import { Link } from 'react-router-dom';
import { ImageUrl } from '../../../Config';
import Header from '../common/Header';
import Footer from '../common/Footer';

const allBlog = [
  {
    id: 1,
    image: ImageUrl+"images/blog/1.jpg",
    heading: "Why ATUM Is The Ultimate Roofing Solution To Resist Extreme Weathers",
    desc: "Often undervalued, choosing a sturdy roof is key to a healthy building. And with the cyclones and heavy winds impending, it's time to brace the..."
  },
  {
    id: 2,
    image: ImageUrl+"images/blog/2.jpg",
    heading: "Why The World Needs To Go Solar Now More Than Ever",
    desc: "ATUM Solar Roof is both an affordable and energy-efficient addition to your building. Read on to know why the right time to go solar is now!"
  },
  {
    id: 3,
    image: ImageUrl+"images/blog/3.jpg",
    heading: "Five Reasons Why You Should Buy Your Solar Rooftop Plant During A Recession",
    desc: "With investment into solar power today being merely negligible, there are numerous financing schemes by banks, NBFCs and private investors that are eager..."
  },
  {
    id: 4,
    image: ImageUrl+"images/blog/4.jpg",
    heading: "Why Investing In A Solar Roof Is The Only Trustworthy Scheme To Make Money",
    desc: "You might have come across numerous schemes that lure the public, promising a monthly income while sitting at home. Most of these turn out to be Ponzi"
  },
  {
    id: 5,
    image: ImageUrl+"images/blog/5.jpg",
    heading: "Top Ten Reasons To Choose A Solar Roof And Not A Typical Solar Panel",
    desc: "Choosing the right solar solution can be a tough call with so many choices around. But your preference has an impact on your finances, building facilities,"
  },
  {
    id: 6,
    image: ImageUrl+"images/blog/6.jpg",
    heading: "Solar Glossary 101 What You Need To Know",
    desc: "With investment into solar power today being merely negligible, there are numerous financing schemes by banks, NBFCs and private investors that are eager..."
  },
  {
    id: 7,
    image: ImageUrl+"images/blog/7.jpg",
    heading: "Why Going Solar Is The Best Alt Energy Solution For The Future",
    desc: "Rooftop solar is taking over the globe like never before. Thinking twice to switch to solar? We list down five reasons why solar PV modules are a bright idea."
  },
];

const Blog = () => {
  const [blogIb, setblogIb] = useState('');

  useEffect(() => {
    let blogInsideSlider = InsideBannerList.filter((item) => item.title === 'blog');
    setblogIb(blogInsideSlider[0]);
  }, []);

  return (
    <div>
      <Header/>
        <section className="inner-banner" style={{ backgroundImage: `url(${blogIb.image})` }} >
          <div className="container">
            <div className="row">
              <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">{blogIb.heading}</h2>
            </div>
          </div>
        </section>
        <section className="blog py-5 bg-gray">
          <div className="container">
            <div className="row">
              {allBlog && allBlog.map((item, index) =>
                <div className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-3" key={index}>
                  <div className="blog-entry bg-white border rounded shadow p-2">
                    <div className="entry-content">
                      <div className="entry-img-wrap">
                        <div className="entry-img">
                          <Link className="text-dark fs-5 fw-semibold text-decoration-none" to={`/blog/${item.heading.toLowerCase().replace(/ /g, "-")}`}>
                            <div className="blog_media" style={{ backgroundImage: `url(${item.image})` }} ></div>
                          </Link>
                        </div>
                      </div>
                      <div className="entry-title">
                        <h5 className="pt-3"><Link to={`/blog/${item.heading.toLowerCase().replace(/ /g, "-")}`} className="fs-6 fw-semibold text-decoration-none text-black">{item.heading}</Link></h5>
                      </div>
                      <div className="entry-bio">
                        <p className="website-para-textstyle text-gray">{item.desc}</p>
                      </div>
                      <div className="primary-50-bg primary-text fs-6 fw-semibold cursor-pointer p-1 px-2">
                        <Link to={`/blog/${item.heading.toLowerCase().replace(/ /g, "-")}`} className="m-0 p-0 primary-text">Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Blog