import React, { useEffect, useState } from 'react';
import { InsideBannerList } from './Innerbanner';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { ImageUrl } from '../../../Config';
import { useParams } from "react-router-dom";
import products from '../../../products.json';
import { ErrorPage } from '../../../ErrorPage';

const TechnicalSpecifications = () => {
    let { id } = useParams();

    const [state, setState] = useState({});

    useEffect(() => {
        let techspcInsideSlider = InsideBannerList.filter((item) => item.title === 'technical-specs');
        setState(techspcInsideSlider[0]);
    }, []);

    try
    {
        if (typeof products[id] === "undefined") { throw new Error("Invalid product ID passed, please try again with valid product."); }
        if (typeof products[id].title === "undefined") { throw new Error("Invalid product request found, please try again with valid product."); }
        if (typeof products[id]['technical-spects'] === "undefined") { throw new Error("Technical details not found for the product choosen, please try again."); }

        return (
            <div>
                <Header />
                <section className="inner-banner" style={{ backgroundImage: `url(${state.image})` }} >
                    <div className="container">
                        <div className="row">
                            <h2 className="text-white text-uppearcase inner-banner-title fs-1 fw-semibold text-center position-absolute top-50 start-50 translate-middle">
                                <b>{products[id].title}</b>
                                <br />
                                <small>{state.heading}</small>
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="specifications py-5">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-12 col-sm-12 col-md-2 col-lg-2 mx-auto">
                                <div className="tech-spec">
                                    <img src={ImageUrl + 'images/tech-spec.png'} alt="" className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-10 col-lg-10 mx-auto accordn">
                                <div className="accordion" id="technicalSpectsAccordion">
                                    {
                                        Object.keys(products[id]['technical-spects']).map((p, pIndex) => {
                                            const technicalPropKeys = Object.keys(products[id]['technical-spects'][p]);
                                            const technicalPropValues = Object.values(products[id]['technical-spects'][p]);
                                            const technicalPropRows = technicalPropKeys.map((pp, ppIndex) => { return (<tr key={ppIndex}><td>{pp}</td><td>{technicalPropValues[ppIndex]}</td></tr>); });
                                            return (
                                                <div className="accordion-item" key={pIndex}>
                                                    <h2 className="accordion-header" id={`heading${pIndex}`}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${pIndex}`} aria-expanded="false" aria-controls="collapseOne">{p}</button>
                                                    </h2>
                                                    <div id={`collapse${pIndex}`} className="accordion-collapse collapse" aria-labelledby={`heading${pIndex}`} data-bs-parent="#technicalSpectsAccordion">
                                                        <div className="accordion-body">
                                                            <table className="table table-striped">
                                                                <tbody>{technicalPropRows}</tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }catch(error){ return <ErrorPage title="404!" desc={error.message} path="/" pathName="Home" />; }
}

export default TechnicalSpecifications